export function server(path) {
    if(path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://localhost:5001/${path}`;
    }
    return `https://ekonatim.rs/${path}`;
}
export function graphQL() {
    return server("graphql");
}
export function ws() {
    return "wss://asortiman.com:8000/ws"
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return "ws://localhost:8000/ws";
    }
    return "wss://asortiman.com:8000/ws"
}

